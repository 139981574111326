// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Hero = ({ header, text, image }) => (
  <BgImage
    image={getImage(image?.localFile.childImageSharp?.gatsbyImageData)}
    className="hero__background"
  >
    <Container className={`hero -blank ${image ? "-white" : ""}`}>
      <Row>
        <Col lg="15">
          <h1
            className="hero__header"
            dangerouslySetInnerHTML={{ __html: header }}
          />
          {text && <p className="hero__text">{text}</p>}
        </Col>
      </Row>
    </Container>
  </BgImage>
);

export default Hero;
