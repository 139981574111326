import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const solutionsPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Solutions {
                  solutionsRichContent {
                    # SECTION: Hero
                    hero {
                      header
                      text
                      image {
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                        }
                      }
                    }

                    imageSections {
                      imageSection {
                        title
                        text
                        slug
                        variant
                        button {
                          target
                          url
                          title
                        }
                        image {
                          ...wpImageFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "solutionsRichContent");
};

export { solutionsPageTplData };
